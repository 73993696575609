import React, { useRef, useState, useEffect, Fragment } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useOnClickOutside from 'use-onclickoutside'
import { Menu, Transition } from '@headlessui/react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faBars,
  faPlus,
  faClipboardList,
  faList,
  faCog,
  faStar,
  faInfo,
  faUser,
  faQuestion
} from '@fortawesome/free-solid-svg-icons'
import { push } from 'connected-react-router'
import clsx from 'clsx'

const NavItem = ({ path, title, icon, yellow, onClose, className }) => {
  const dispatch = useDispatch()

  const handleNavClick = () => {
    onClose()
    dispatch(push(path))
  }

  return (
    <Menu.Item as={'div'} className={clsx("text-center cursor-pointer", className)} onClick={handleNavClick}>
      <FontAwesomeIcon icon={icon} className={`text-3xl ${yellow && 'text-yellow-500'}`} />
      <span className="font-semibold text-base block mt-1">{title}</span>
    </Menu.Item>
  )
}

const NavDropdown = ({ navOpen, onClose }) => {
  const ref = useRef(null)
  const isAuthenticated = useSelector(store => store.session.isAuthenticated)
  const user = useSelector(store => store.user.user)

  const [premiumUrl, setPremiumUrl] = useState('/premium')

  useEffect(() => {
    if (isAuthenticated && user && user.delinquent) {
      setPremiumUrl('/premium/renew')
    }
  }, [user, isAuthenticated])

  useOnClickOutside(ref, e => {
    if (navOpen && e.target.id !== 'navControl') {
      onClose()
    }
  })

  return (
    <Menu as="div">
      <Menu.Button as={React.Fragment}>
        <FontAwesomeIcon
          icon={faBars}
          id="navControl"
          className="lg:hidden cursor-pointer text-2xl text-white mt-1"
          onClick={() => {}}
        />
      </Menu.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-200"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-200"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <div className="absolute z-10 mt-2 -ml-3 mr-3 origin-top-right rounded-lg bg-black text-white shadow-lg focus:outline-none">
          <Menu.Items as={'div'} className="p-8 grid grid-cols-3 gap-x-6 gap-y-8">
            <NavItem path="/new" title="New Request" icon={faPlus} onClose={onClose} />

            {!isAuthenticated && <NavItem path="/sign-in" title="Your Account" icon={faUser} onClose={onClose} />}
            {isAuthenticated && <NavItem path="/" title="Requests" icon={faList} onClose={onClose} />}
            {isAuthenticated && <NavItem path="/settings" title="Settings" icon={faCog} onClose={onClose} />}

            <NavItem path="/faq" title="Questions" icon={faQuestion} onClose={onClose} />
            <NavItem path="/about" title="About" icon={faInfo} onClose={onClose} />
            <NavItem path="/logs" title="Logs" icon={faClipboardList} onClose={onClose} />

            <NavItem path={premiumUrl} title="Go Premium" icon={faStar} yellow onClose={onClose} className="col-start-2" />

          </Menu.Items>
        </div>
      </Transition>
    </Menu>
  )
}

export default NavDropdown
