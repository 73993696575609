import React from 'react'
import clsx from 'clsx'
import { Link } from 'react-router-dom'

export const buttonColors = {
  gray: 'bg-gray-100 border-gray-400 text-gray-600',
  green: 'bg-green-100 border-green-600 text-green-600',
  darkGreen: 'bg-green-500 border-green-500 text-green-100',
  red: 'bg-red-100 border-red-300 text-red-500',
  orange: 'bg-orange-100 border-orange-400 text-orange-600',
  orangeFull: 'bg-orange-200 border-orange-300 text-grey-700',
  black: 'bg-gray-500 border-gray-800 text-gray-50',
  white: 'bg-white border-orange-200 text-gray-700',
}

const classNameAll =
  'flex-initial rounded-md border-2 font-semibold tracking-wide disabled:opacity-50 disabled:cursor-wait'

const classNameLarge = 'text-lg p-2 px-8'
const classNameSmall = 'p-2 px-3'

const classNameSizes = {
  lg: classNameLarge,
  sm: classNameSmall,
  xs: 'text-sm p-1'
}

export const Button = ({ children, className, color, small, size='lg', type='button', ...props }) => (
  <button
    type={type}
    {...props}
    className={clsx(className, classNameAll, small ? classNameSmall : classNameSizes[size], buttonColors[color], '')}
  >
    {children}
  </button>
)

export const ButtonExt = ({ children, className, color, ...props }) => (
  <a {...props} className={clsx(className, classNameAll, classNameLarge, buttonColors[color], 'text-center block ')}>
    {children}
  </a>
)

export const ButtonLink = ({ children, className, color, size='lg', ...props }) => (
  <Link {...props} className={clsx(className, classNameAll, classNameSizes[size], buttonColors[color], 'text-center block ')}>
    {children}
  </Link>
)

export default Button
