import React from 'react'
import clsx from 'clsx'

const Colors = {
  white: 'bg-white border-gray-400',
  gray: 'bg-gradient-to-b  from-gray-200 to-gray-100 border-gray-400',
  greenOrig: 'bg-green-100 border-green-400',
  green: 'bg-gradient-to-b from-green-200 to-green-100 border-green-400',
  darkGreen: 'bg-green-500 border-green-500 text-green-100',
  red: 'bg-red-100 bg-opacity-40 border-red-500 text-gray-600',
  orange: 'bg-orange-100 border-orange-400',
  lightOrange: 'bg-orange-100 border-orange-200 text-orange-600',
  darkOrange: 'bg-orange-500 border-orange-500 text-white',
}

export const Card = ({ children, className, color = 'green', small, ...props }) => (
  <div {...props} className={clsx(className, Colors[color], small ? 'p-2' : 'p-4', ' rounded border-t-2 shadow-sm')}>
    {children}
  </div>
)

export default Card
