import { AVAILABILITY_REQUEST } from '../utils/constants'
export const initialState = {
  step: 1,
  submitting: false,
  siteSelector: false,
  error: null,
  ref: null,
  clone: null
}

const requestFormReducer = (state = initialState, { type, payload }) => {
  if (type === '@@router/LOCATION_CHANGE' && payload.action === 'PUSH' && payload.location.pathname === '/new') {
    state.ref && state.ref.current && state.ref.current.resetForm({ values: { ...AVAILABILITY_REQUEST.initialValues } })
    return { ...initialState, clone: state.clone }
  }

  switch (type) {
    case 'FORM_STEP_GO':
      return { ...state, step: payload, error: null }
    case 'SUBMIT_REQUEST_FORM':
      return { ...state, submitting: true, error: null }
    case 'SUBMIT_REQUEST_FORM_SUCCESS':
      return { ...state, submitting: false, error: null }
    case 'SITE_SELECTOR':
      return { ...state, siteSelector: payload }
    case 'SUBMIT_REQUEST_REJECTED':
      return { ...state, submitting: false, error: payload }
    case 'REQUEST_FORM_CLONE':
      return { ...state, clone: payload }
    case 'REQUEST_FORM_REF':
      return { ...state, ref: payload }
    default:
      return { ...state }
  }
}

export default requestFormReducer
