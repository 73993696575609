import React from 'react'
import { Route, Switch } from 'react-router'
import { ConnectedRouter } from 'connected-react-router'

import { history } from '../../utils/history'
import Header from '../layout_v2/Header'
import LayoutV2 from '../layout_v2/Index'

const Admin = React.lazy(() => import('../Admin/Index'))

const Loading = () => {
  return (
    <div className="sans bg-white text-gray-700 min-h-screen">
      <Header navClick={() => {}} />
    </div>
  )
}

const Layout = () => {
  return (
    <ConnectedRouter history={history}>
      <React.Suspense fallback={<Loading />}>
        <Switch>
          <Route exact path="/admin/*" component={Admin} />
          <Route exact path="/" component={LayoutV2} />
          <Route exact path="/*" component={LayoutV2} />
        </Switch>
      </React.Suspense>
    </ConnectedRouter>
  )
}

export default Layout
