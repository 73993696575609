import React from 'react'

export const Join = ({ items, seperator = ', ' }) => {
  const filteredItems = items.filter(i => i !== undefined && i !== 0)

  return filteredItems.length > 0 ? (
    filteredItems.reduce((result, item) => (
      <React.Fragment>
        {result}
        {seperator}
        {item}
      </React.Fragment>
    ))
  ) : (
    <React.Fragment />
  )
}

export const JoinAnd = ({ items }) => {
  const str = ', '

  return items.length > 0 ? (
    items.reduce((result, item, index) => (
      <React.Fragment>
        {result}
        {index + 1 === items.length ? (index === 1 ? ' and ' : ', and ') : str}
        {item}
      </React.Fragment>
    ))
  ) : (
    <React.Fragment />
  )
}
