/* eslint-disable no-sequences */
import { useState, useCallback, useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'

import axios from 'axios'
import config from './config'
import store from '../store'
import { setFlashMessage } from '../actions/flashActions'

const ax = axios.create()

export const useLoader = () => {
  const [counter, setCounter] = useState(0)
  const inc = useCallback(() => setCounter(counter => counter + 1), [setCounter]) // add to counter
  const dec = useCallback(() => setCounter(counter => counter - 1), [setCounter]) // remove from counter

  const interceptors = useMemo(
    () => ({
      request: config => (inc(), config),
      response: response => (dec(), response),
      error: error => (dec(), Promise.reject(error))
    }),
    [inc, dec]
  ) // create the interceptors

  useEffect(() => {
    // add request interceptors
    const reqInterceptor = ax.interceptors.request.use(interceptors.request, interceptors.error)
    // add response interceptors
    const resInterceptor = ax.interceptors.response.use(interceptors.response, interceptors.error)
    return () => {
      // remove all intercepts when done
      ax.interceptors.request.eject(reqInterceptor)
      ax.interceptors.response.eject(resInterceptor)
    }
  }, [interceptors])

  return counter > 0
}

export const useApiDown = () => {
  const dispatch = useDispatch()

  ax.interceptors.response.use(success => success, error => {
    if (error.response.status === 502) {
      dispatch(setFlashMessage('Unable to establish connection with Wandering Labs backend. Please attempt again in a moment.', 'error'))
    }
    return Promise.reject(error)
  })
}

ax.interceptors.request.use(axiosConfig => {
  const token = store.getState().session.token

  axiosConfig.baseURL = config.reserveApiUrl
  axiosConfig.headers.Authorization = token ? `Token token=${token}` : ''
  return axiosConfig
})

export default ax
export const getDefaultAdapter = () => ax.defaults.adapter

// var instance = axios.create({
//   baseURL: config.reserveApiUrl
// });

// export const reserveApi = options => {
//   let token = store.getState().session.token;
//   Object.assign(options, {
//     headers: {
//       Authorization: token ? `Token token=${token}` : ""
//     }
//   });
//   return instance(options);
// };

// export default reserveApi;

// export const getDefaultAdapter = () => axios.defaults.adapter;
