import reserveApi from '../utils/axios'
import { setFlashMessage } from '../actions/flashActions'
import { push } from 'connected-react-router'

export function userSettings() {
  return function (dispatch) {
    dispatch({ type: 'FETCH_USER' })
    reserveApi({
      method: 'get',
      url: '/users'
    })
      .then(function (response) {
        dispatch({ type: 'FETCH_USER_FULFILLED', payload: response.data })
      })
      .catch(function (error) {
        localStorage.setItem('token', null)
        dispatch({ type: 'FETCH_USER_REJECTED', payload: error })
      })
  }
}

export function userSurvey(answer) {
  return function (dispatch) {
    dispatch({ type: 'FETCH_USER_SURVEY' })
    return reserveApi({
      method: 'get',
      url: '/users/survey',
      params: { answer }
    })
      .then(function (response) {
        dispatch({ type: 'FETCH_USER_SURVEY_FULFILLED', payload: response.data })
      })
      .catch(function (error) {
        localStorage.setItem('token', null)
        dispatch({ type: 'FETCH_USER_SURVEY_REJECTED', payload: error })
        dispatch(push('/'))
      })
  }
}

export function userSurveySave(answers, comments = '') {
  return function (dispatch) {
    reserveApi({
      method: 'post',
      url: '/users/survey_save',
      data: {
        survey_who_1: answers[0],
        survey_who_2: answers[1],
        survey_who_3: answers[2],
        survey_who_4: answers[3],
        survey_who_5: answers[4],
        survey_who_comment: comments
      }
    }).catch(function (error) {})
  }
}

export function paymentSuccess({ provider, product, response, name, email }) {
  return function (dispatch) {
    dispatch({ type: 'SET_PREMIUM' })
    dispatch({ type: 'PAYMENT' })
    dispatch(setFlashMessage('Payment Successful!!', 'success'))
    let apiValues = {
      provider,
      product,
      name,
      email,
      details: response
    }

    reserveApi({
      method: 'post',
      url: '/payments',
      data: {
        payment: apiValues
      }
    })
      .then(function (response) {
        dispatch({ type: 'FETCH_USER_FULFILLED', payload: response.data })
        dispatch({ type: 'PAYMENT_FULFILLED', payload: response.data })
      })
      .catch(function (error) {
        dispatch({ type: 'PAYMENT_REJECTED', payload: error })
      })
  }
}

export function addNotificationMethod(nmType, data) {
  return function (dispatch) {
    dispatch({ type: 'NOTIFICATION_LOADING', payload: nmType })

    let apiValues = {
      notification_type: nmType,
      param: data
    }

    reserveApi({
      method: 'post',
      url: '/notification_methods',
      data: {
        notification_method: apiValues
      }
    })
      .then(function (response) {
        dispatch({ type: 'NOTIFICATION_LOADING', payload: null })
        dispatch({ type: 'FETCH_USER_FULFILLED', payload: response.data })
      })
      .catch(function (error) {})
  }
}

export function deleteNotificationMethod(nmType, id) {
  return function (dispatch) {
    dispatch({ type: 'NOTIFICATION_LOADING', payload: nmType })

    reserveApi({
      method: 'delete',
      url: `/notification_methods/${id}`
    })
      .then(function (response) {
        dispatch({ type: 'NOTIFICATION_LOADING', payload: null })
        dispatch({ type: 'FETCH_USER_FULFILLED', payload: response.data })
      })
      .catch(function (error) {})
  }
}

export function testNotificationMethod(data) {
  return function (dispatch) {
    return reserveApi({
      method: 'post',
      url: `/notification_methods/${data}/test_notification`
    })// .catch(function (error) {})
  }
}
