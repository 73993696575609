import React from 'react'
import { Helmet } from 'react-helmet'

const Meta = () => {
  return (
    <>
      <Helmet>
        <title>
          Wandering Labs :: Reserve - wanderingLabs.com - Campsite reservation and cancellation notifications
        </title>
        <meta
          name="description"
          content="Tell us where you want to camp, select a date range, and receive a notification when a site becomes available. For example, submit a request to stay at Bahia Honda State Park in Florida for 5 consecutive nights anytime between January 1 and March 1. We will check the reservation website every few minutes and if a site becomes available you will receive an email."
        />
        <meta name="robots" content="index, follow" />
        <meta name="googlebot" content="index, follow" />
        <meta name="bingbot" content="index, follow" />
        <link rel="canonical" href="https://wanderinglabs.com" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta
          property="og:title"
          content="Wandering Labs :: Reserve - wanderingLabs.com - Campsite reservation and cancellation notifications"
        />
        <meta
          property="og:description"
          content="Tell us where you want to camp, select a date range, and receive a notification when a site becomes available. For example, submit a request to stay at Bahia Honda State Park in Florida for 5 consecutive nights anytime between January 1 and March 1. We will check the reservation website every few minutes and if a site becomes available you will receive an email."
        />
        <meta property="og:url" content="https://wanderinglabs.com" />
        <meta property="og:site_name" content="WanderingLabs :: Reserve" />
      </Helmet>
    </>
  )
}
export default Meta
