import React from 'react'
import { JoinAnd } from '../components/utils/Join'

export const SESSION_SUCCESS = 'Your login url is being emailed to you.'
export const SESSION_FAILURE = 'Unable to locate account. Creating a new request will create an account.'
export const SESSION_BAD_TOKEN = 'Bad Login Token. Please enter your email to generate a new login token.'
export const SESSION_LOGGED_OUT = 'You have been logged out.'
export const SITE_DOWN = 'Server Unavailable. Can not complete request. Please try again shortly.'
export const SMS_TEST_SUCCESS = 'A test SMS has been successfully sent.'
export const SMS_TEST_FAILURE = 'Test has failed. Please review details and try again.'

export const SITE_TYPES = {
  rv: 'RV',
  other: 'Other',
  group: 'Group',
  tent: 'Tent',
  tentWalkIn: 'Tent (Walk In)'
}

export const AVAILABILITY_REQUEST = {
  initialValues: {
    uuid: null,
    facilityId: null,
    dateStart: null,
    dateEnd: null,
    stayLength: '',
    arrivalDays: [],
    minLength: '',
    type: 'rv',
    minElectric: '',
    water: false,
    sewer: false,
    pullthru: false,
    sitePremium: false,
    ignoreAda: true,
    petsAllowed: false,
    email: '',
    specificSiteIds: [],
    matchingSitesCount: 0
  },
  errors: {
    dupe: 'Request is a duplicate of an existing active reqest. Please review to see if that active request is enough. Reach out to us if you are having trouble creating a request that matches your needs.',
    freeLimit:
      'Free accounts are limited to 3 active requests. Premium accounts are unlimited. Please upgrade or remove an existing request before creating another.'
  }
}

export const ADMIN_USER = {
  initialValues: {
    id: null,
    premium: false,
    premiumUntil: null,
    adminNotes: '',
  },
  errors: {}
}

export const MARKETING = {
  points: {
    free: ['3 Requests', 'Email Notifications', 'Paused Requests'],
    premium: ['Unlimited Requests', '500 TXT/SMS Notifications', 'Scrape Twice as Often'],
    upgrade: [
      'Check twice as often (high demand locations every minute)',
      '500 Txt alerts',
      'Unlimited Requests',
      'No pausing (Free requests pause every week)'
    ],
    renew: [
      'Good for another year',
      'Unlimited Requests',
      'Refills txt quotas'
    ]
  }
}

export const PAYMENTS = {
  success: 'Payment Successful!'
}

export const PRODUCTS = {
  upgrade: {
    value: 30,
    color: 'bg-orange-600'
  },
  renew: {
    value: 30,
    color: 'bg-orange-500'
  },
  thanks: {
    value: 20,
    color: 'bg-orange-400'
  },
  sms: {
    value: 10,
    items: [
      {
        count: 200,
        value: 5,
        edit: true
      },
      {
        count: 500,
        value: 10
      },
      {
        count: 1000,
        value: 15
      }
    ],
    color: 'bg-orange-300'
  }
}

export const PAYPAL_IDS = {
  sandbox: 'AaoKUckTip-ExcrlptmKarrQpOek0xDorQqEs9I9VyYoM1ozhNsPa8QZmgp_UEitynINt1Wt4ncWtYeU',
  production: 'AcqPTM8bCEXwaIc9suZyU33ofPP9LkBXATmMVcqsVireC4QwU7h3eOp4u1-opx0Q-G5LexcUWZbJuyYe'
}

export const STRIPE_KEYS = {
  live: 'pk_live_m44jLFc5UpePCSQm5ksVIjvA',
  test: 'pk_test_F4NB0gahhlAGrTqU3onPbkcd'
}

export const STATES = {
  AL: 'Alabama',
  AK: 'Alaska',
  AZ: 'Arizona',
  AR: 'Arkansas',
  CA: 'California',
  CO: 'Colorado',
  CT: 'Connecticut',
  DE: 'Delaware',
  FL: 'Florida',
  GA: 'Georgia',
  HI: 'Hawaii',
  ID: 'Idaho',
  IL: 'Illinois',
  IN: 'Indiana',
  IA: 'Iowa',
  KS: 'Kansas',
  KY: 'Kentucky',
  LA: 'Louisiana',
  ME: 'Maine',
  MD: 'Maryland',
  MA: 'Massachusetts',
  MI: 'Michigan',
  MN: 'Minnesota',
  MS: 'Mississippi',
  MO: 'Missouri',
  MT: 'Montana',
  NE: 'Nebraska',
  NV: 'Nevada',
  NH: 'New Hampshire',
  NJ: 'New Jersey',
  NM: 'New Mexico',
  NY: 'New York',
  NC: 'North Carolina',
  ND: 'North Dakota',
  OH: 'Ohio',
  OK: 'Oklahoma',
  OR: 'Oregon',
  PA: 'Pennsylvania',
  RI: 'Rhode Island',
  SC: 'South Carolina',
  SD: 'South Dakota',
  TN: 'Tennessee',
  TX: 'Texas',
  UT: 'Utah',
  VT: 'Vermont',
  VA: 'Virginia',
  WA: 'Washington',
  WV: 'West Virginia',
  WI: 'Wisconsin',
  WY: 'Wyoming'
}

export const FAQS = [
  {
    question: 'What is Wandering Labs?',
    body: (
      <>
        <p>
          We find campsites for you at already sold out campgrounds. You know how it’s becoming harder and harder to
          book a site at your favorite campground, even if you try months in advance? You know how you got up at 5 am
          four days in a row and tried to get a campsite at Yosemite National Park for your family vacation, and still
          ended up at a dumpy, run down RV park 60 miles from the park entrance? Yeah, we know how you feel and we’re
          here to help!
        </p>
        <p>
          With campgrounds filling up faster than ever, we discovered that the best way to get a site is when someone’s
          plans change and they cancel their reservation. To do this you could monitor the website and refresh the page
          over and over again in hopes that a site opens. Or…you could tell us what campground you want to camp at and
          let us do all the work for you. Simply tell us the specifics of your search and we will scan the campground of
          your choice for cancellations and notify you when a site becomes available. No more getting up at 5 am.
        </p>
      </>
    )
  },

  {
    question: 'How do I create a request for a campsite?',
    body: (
      <>
        <p>Click on the New Request button</p>

        <p>
          Step 1: Enter the campground where you wish to camp in the search bar.
          <br />
          Step 2: Choose the date range of your search starting with the first day you want to arrive. Choose the stay
          length by entering the minimum number of days you want to camp.
          <br />
          Step 3: Choose your search filters such as RV or Tent sites, length of site, utility hook-ups, and an option
          to limit the search to specific sites.
        </p>

        <p>Click submit and you are ready to go!</p>
      </>
    )
  },

  {
    question: 'How much does it cost?',
    body: (
      <>
        <p>
          Free: Try out our service by creating up to 3 requests for no cost. The free option does not search for
          openings as often and only sends notifications via email.
        </p>
        <p>
          Premium: We offer a paid Premium Membership for $30. The stated length of service is one year. However, at
          this time we are not removing premium status or limiting features after one year.
        </p>
        <p>
          Becoming a premium member unlocks the following benefits:
          {MARKETING.points.premium.map((point, index) => (
            <span key={index} className="block">
              - {point}
            </span>
          ))}
        </p>
      </>
    )
  },

  {
    question: 'What happens if I sign up for a premium membership and use all of my 500 SMS messages?',
    body: (
      <>
        <p>
          If you reach the 500 txt limit, your requests will continue to be checked and you will still be notified by
          email. We offer additional txt packages:{' '}
          <JoinAnd
            items={PRODUCTS.sms.items.map(sms => (
              <span key={sms.count}>
                <span className="font-semibold">{sms.count}</span> for{' '}
                <span className="font-semibold">${sms.value}</span>
              </span>
            ))}
          />
        </p>
      </>
    )
  },

  {
    question: 'How quickly will I reach the 500 SMS limit?',
    body: (
      <>
        <p>
          It depends how you use the site. Some users reach the limit in a few months, while others never do. In
          general, a high number of requests, searches with long date ranges, searches with short stay lengths, and
          adding multiple phone numbers for notifications can all contribute to high message use.
        </p>
      </>
    )
  },

  {
    question: 'How do I cancel or edit a request?',
    body: (
      <>
        <p>
          You can cancel or edit a request in the Requests section of your account. Click on each individual request and
          choose the green edit button or the red cancel button.
        </p>
      </>
    )
  },

  {
    question: 'Can I search for individual sites within a campground?',
    body: (
      <>
        <p>
          Yes! In Step 3 of the Create a Request process you can choose to either scan all the campsites that match your
          search criteria, or narrow the result to specific campsites or sections of the campground.
        </p>
      </>
    )
  },

  {
    question:
      'If I create a request with a stay length of 2 nights and a site opens up for 4 nights inside my arrival window will I still be notified?',
    body: (
      <>
        <p>
          You sure will. The stay length is the minimum number of days you would like to camp. If you set it for 2
          nights and a site opens for any length of time more than 2 nights during your arrival dates, we will notify
          you.
        </p>
      </>
    )
  },

  {
    question: 'How do you notify me when a site becomes available?',
    body: (
      <>
        <p>
          Our default notification method for free searches is email. If you sign up for our paid Premium service, in
          addition to email, we also offer SMS text messages and browser notifications. For each individual request you
          can choose your notification method(s).
        </p>
      </>
    )
  },

  {
    question: 'How do I add or change the phone number or email address used for notifications?',
    body: (
      <>
        <p>
          You can add or change a phone number or email address in the Settings tab. The phone number field is only
          visible for premium accounts.
        </p>
      </>
    )
  },

  {
    question:
      'Help! I set up several requests and now I am getting duplicate notifications for campsites. What do I do?',
    body: (
      <>
        <p>
          The most common reason for duplicate notifications is multiple requests for the same campground with different
          stay lengths. If you create a request for 2 nights, we will notify you anytime a site opens for at least 2
          nights. Therefore, creating a separate request at the same campground for 4 nights could result in duplicate
          notifications.
        </p>
        <p>
          The second most common reason for receiving duplicate notifications is due to multiple requests for the same
          campground with overlapping dates. For example, if you have one request for Cherry Creek State Park with
          arrival dates between 7/29 - 8/4 for a minimum of 4 nights, and another request for the same campground with
          arrival dates of 8/3 - 8/ 7 for a minimum of 2 days and a site opens on 8/3 for 4 nights, you will receive two
          notifications.
        </p>
        <p>
          If you’ve checked your requests and still feel you are receiving duplicate notifications, please reach out and
          one of us will take a look.
        </p>
      </>
    )
  },

  {
    question: 'What campgrounds do you search?',
    body: (
      <>
        <p>
          We search most (but not all) of the public campgrounds in the United States. You can see a full list of the
          campground agencies that we serve by clicking the Filter by Agency button in the New Request form.
        </p>
      </>
    )
  },

  {
    question: 'Can you add this campground, feature, etc…?',
    body: (
      <>
        <p>
          Hopefully, maybe, yes. While we cannot fill all requests, we try our best to add new campgrounds and features
          as often a possible. Work is always happening behind the scenes to improve this service for you.
        </p>
      </>
    )
  },

  {
    question: 'Will you reserve a site for me?',
    body: (
      <>
        <p>
          Nope, sorry. We are not a reservation service. We simply check for open sites and notify you when they become
          available. Reserving the site is up to you.
        </p>
      </>
    )
  },

  {
    question: 'Is this service still working? I haven’t gotten a notification in awhile.',
    body: (
      <>
        <p>
          You can check on the status of your requests but clicking the Requests tab on the right side of your account
          page. Click on each individual request to open for more details. Here you can view the time and date of the
          most recent scan.
        </p>
      </>
    )
  },

  {
    question:
      'I was notified of an open campsite but when I clicked on the link it was already reserved. Why are you sending me notifications for reserved sites?',
    body: (
      <>
        <p>
          Unfortunately, we cannot guarantee that a site will still be available when you click on the link. Chances are
          that another camper reserved the site before you (and yes, this can happen in less than a minute). When you
          receive a notification, act as quickly as possible to get that site reserved in your shopping cart before
          someone else grabs it!
        </p>
      </>
    )
  },

  {
    question:
      'I heard that bots are reserving all the campsites and normal people can’t ever get a site. What can we do about this?',
    body: (
      <>
        <p>
          First of all, let’s clear up the misconception regarding campsite booking bots. While there have been
          incidents in the past where companies were able to reserve large numbers of campsites and re-sell them, this
          practice is illegal, not to mention immoral. Most campground reservation systems have cracked down on the
          re-selling of campgrounds, and while it’s possible that it still happens occasionally, there is no evidence
          that this is a widespread practice.
        </p>

        <p>
          So if it’s not bots, what’s going on? Well, the biggest issue is that that there are a LOT more people who
          want to go camping than campsites available. Take the Florida Keys for example. There are three highly
          sought-after state parks in the Florida Keys with a combined total of less than 150 sites for RVs and tents.
          At any given time there are many, many more than 150 people who want to stay at a state park in the Florida
          Keys. Because these sites are so popular, they almost always sell out immediately. Not necessarily because a
          bot is booking the sites as soon as they become available, but because there are thousands of people competing
          for the same campsites.
        </p>

        <p>
          What can we do about this? While most of us don’t have the power to create more campsites, we can encourage
          our public campgrounds to expand and improve to better meet current demand. There are many ways to support
          public campgrounds, including donations, volunteering, getting involved in political actions that fund parks,
          and much more.
        </p>

        <p>
          Finally, you also do your part by canceling sites that you no longer need so they are freed up for other
          campers.
        </p>
      </>
    )
  }
]

export const SURVEY_WHO = [
  {
    question: 'Which of the following options best describes your camping style?',
    answers: [
      'I am a flexible camper in search of campsites within a short drive of home over a wide-range of dates.',
      'I am camping enthusiast who is looking for specific dates at specific campgrounds.',
      'I am a “snowbird” RVer who uses the shoulder seasons for re-positioning.',
      'I am full-time RVer who travels for all or most of the year.'
    ]
  },
  {
    question: 'On average, how many times do you make camping reservations per year?',
    answers: ['Once', '3-5 times', 'More than 5 times', 'As often as possible']
  },
  {
    question: 'When reserving through the campground website, how often are reservations sold out or unavailable?',
    answers: ['Always', 'Sometimes', 'Rarely', 'Never']
  },
  {
    question: 'How much do reservation cancellation fees play a part in your decision to make a reservation?',
    answers: [
      'I never cancel reservations so I don’t care about the fee.',
      'I will only make a reservation if I feel the fee to cancel is reasonable.',
      'The cancellation fee is a not a factor in my decision to reserve.',
      'I often cancel reservations and consider the fee a part of my overall camping budget.'
    ]
  },
  {
    question: 'How did you hear about the service that Wandering Labs offers?',
    answers: ['Friend or family', 'Other camper', 'Park staff', 'Youtube, blogs, social media, etc.', 'Internet search']
  }
]
