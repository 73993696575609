import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import Nav from './NavDropdown'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faPlus, faUserAlt, faStar } from '@fortawesome/free-solid-svg-icons'
import { ButtonLink } from '../utils/Button'

const DROPDOWN_NAV_ACTIVE = true
const Header = ({ navClick }) => {
  const currentPath = useSelector(store => store.router.location.pathname)
  const isAuthenticated = useSelector(store => store.session.isAuthenticated)
  const user = useSelector(store => store.user.userV2)

  const premiumRenewalAsk = useMemo(() => isAuthenticated && user && user.delinquent, [user, isAuthenticated])
  const premiumRenewalAsk3 = useMemo(
    () => isAuthenticated && user && user.delinquent && user.delinquentLevel === 3 && !currentPath.includes('premium'),
    [user, isAuthenticated, currentPath]
  )

  return (
    <>
      <div className="relative flex items-center py-3 bg-green-500 pl-6 lg:pl-2 pr-6 space-x-4">
        {DROPDOWN_NAV_ACTIVE && <Nav navOpen={true} onClose={() => false} />}

        {!DROPDOWN_NAV_ACTIVE && (
          <FontAwesomeIcon
            icon={faBars}
            id="navControl"
            className="lg:hidden cursor-pointer text-2xl text-white"
            onClick={navClick}
          />
        )}

        <h1 className="flex-grow font-bold text-gray-100 text-xl inline">
          <Link to="/">Wandering Labs</Link>
        </h1>

        {premiumRenewalAsk && (
          <div className="bg-orange-500 px-2 py-1 rounded-lg text-white font-semibold">
            <Link to="/premium/renew">
              <FontAwesomeIcon icon={faStar} className="text-xl cursor-pointer text-white" /> Renew
            </Link>
          </div>
        )}

        {!premiumRenewalAsk && (
          <>
            <Link to={isAuthenticated ? '/settings' : '/sign-in'}>
              <FontAwesomeIcon icon={faUserAlt} className="text-2xl cursor-pointer text-green-200" />
            </Link>

            <Link to="/new">
              <FontAwesomeIcon icon={faPlus} className="text-2xl cursor-pointer text-green-200" />
            </Link>
          </>
        )}
      </div>

      {premiumRenewalAsk3 && (
        <div className="flex flex-col items-center py-3 bg-orange-500 pl-6 lg:pl-2 pr-6 space-x-2">
          <div className="text-white text-xl font-semibold mb-2">Premium Membership expired</div>
          <ButtonLink to="/premium/renew" color="white" className="w-full" size="xs">
            Renew Now
          </ButtonLink>
        </div>
      )}
    </>
  )
}

export default Header
