export default function reducer(
  state = {
    loaded: {},
    active: []
  },
  action
) {
  switch (action.type) {
    case 'CALENDAR_FACILITY_FULFILLED': {
      return {
        ...state,
        loaded: { ...state.loaded, [action.payload.id]: action.payload },
        active: state.active.concat(action.payload.id)
      }
    }
    case 'CALENDAR_FACILITY_HIDE': {
      return { ...state, active: state.active.filter(facilityId => facilityId !== action.payload) }
    }
    default: {
      return state
    }
  }
}
