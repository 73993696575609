import React, { useRef, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useOnClickOutside from 'use-onclickoutside'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faClipboardList, faList, faCog, faStar, faInfo, faUser, faQuestion } from '@fortawesome/free-solid-svg-icons'
import { push } from 'connected-react-router'

const NavItem = ({ path, title, icon, yellow, onClose }) => {
  const dispatch = useDispatch()

  const handleNavClick = () => {
    onClose()
    dispatch(push(path))
  }

  return (
    <div className="mb-8 text-center cursor-pointer" onClick={handleNavClick}>
      <FontAwesomeIcon icon={icon} className={`text-3xl ${yellow && 'text-yellow-500'}`} />
      <span className="font-semibold text-base block mt-1">{title}</span>
    </div>
  )
}

const Nav = ({ navOpen, onClose }) => {
  const ref = useRef(null)
  const isAuthenticated = useSelector(store => store.session.isAuthenticated)
  const user = useSelector(store => store.user.user)

  const [premiumUrl, setPremiumUrl] = useState('/premium')

  useEffect(() => {
    if (isAuthenticated && user && user.delinquent) {
      setPremiumUrl('/premium/renew')
    }
  }, [user, isAuthenticated])

  useOnClickOutside(ref, e => {
    if (navOpen && e.target.id !== 'navControl') {
      onClose()
    }
  })

  return (
    <nav
      ref={ref}
      className={`lg:flex w-32 flex-col z-50 min-h-screen items-center bg-gray-900 py-4 text-white ${
        navOpen ? 'flex' : 'hidden'
      }`}
    >
      <NavItem path="/new" title="New Request" icon={faPlus} onClose={onClose} />
      {!isAuthenticated && <NavItem path="/sign-in" title="Your Account" icon={faUser} onClose={onClose} />}
      {isAuthenticated && <NavItem path="/" title="Requests" icon={faList} onClose={onClose} />}
      {isAuthenticated && <NavItem path="/settings" title="Settings" icon={faCog} onClose={onClose} />}

      <NavItem path={premiumUrl} title="Go Premium" icon={faStar} yellow onClose={onClose} />
      <NavItem path="/faq" title="Questions" icon={faQuestion} onClose={onClose} />

      <NavItem path="/about" title="About" icon={faInfo} onClose={onClose} />
      <NavItem path="/logs" title="Logs" icon={faClipboardList} onClose={onClose} />
    </nav>
  )
}

export default Nav
