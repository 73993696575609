export default function reducer(
  state = {
    availabilityRequestStats: {},
    availabilityMatchStats: {},
    userStats: {},
    paymentChartMonthly: [],
    paymentChartDaily: [],
    paymentChartBestMonthly: [],
    paymentChartBestDaily: [],
    paymentChartBestWeekly: [],
    availabilityRequestMatches: Array.from({ length: 100 }, () => ({ id: null })),
    availabilityRequestNotifications: Array.from({ length: 100 }, () => ({ id: null })),
    scrapersLast: [],
    scrapersQueues: [],
    users: [],
    user: {},
    fetching: false,
    fetched: false,
  },
  action
) {
  switch (action.type) {
    case 'FETCH_ADMIN_AVAILABILITY_REQUEST_STATS_FULFILLED': {
      return { ...state, availabilityRequestStats: action.payload }
    }
    case 'FETCH_ADMIN_AVAILABILITY_MATCH_STATS_FULFILLED': {
      return { ...state, availabilityMatchStats: action.payload }
    }
    case 'FETCH_ADMIN_USER_STATS_FULFILLED': {
      return { ...state, userStats: action.payload }
    }
    case 'FETCH_ADMIN_PAYMENT_CHART_FULFILLED': {
      return { ...state, [`paymentChart${action.payload.type}`]: action.payload.results }
    }
    case 'FETCH_ADMIN_PAYMENT_CHART_MONTHLY_FULFILLED': {
      return { ...state, paymentChartMonthly: action.payload }
    }
    case 'FETCH_ADMIN_PAYMENT_CHART_DAILY_FULFILLED': {
      return { ...state, paymentChartDaily: action.payload }
    }
    case 'FETCH_ADMIN_AVAILABILITY_REQUEST_MATCHES_FULFILLED': {
      return { ...state, availabilityRequestMatches: action.payload }
    }
    case 'FETCH_ADMIN_AVAILABILITY_REQUEST_NOTIFICATIONS_FULFILLED': {
      return { ...state, availabilityRequestNotifications: action.payload }
    }
    case 'FETCH_ADMIN_USERS_FULFILLED': {
      return { ...state, users: action.payload }
    }
    case 'FETCH_ADMIN_USER_FULFILLED': {
      return { ...state, user: action.payload }
    }
    case 'FETCH_ADMIN_SCRAPERS_LAST_FULFILLED': {
      return { ...state, scrapersLast: action.payload }
    }
    case 'FETCH_ADMIN_SCRAPERS_QUEUES_FULFILLED': {
      return { ...state, scrapersQueues: action.payload }
    }
    case 'FETCH_ADMIN_FETCHING': {
      return { ...state, fetching: true, fetched: false }
    }
    case 'FETCH_ADMIN_FETCHED': {
      return { ...state, fetching: false, fetched: true }
    }
    default: {
      return state
    }
  }
}
