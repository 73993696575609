import camelcaseKeys from 'camelcase-keys'

const initialValues = {
  facility: { name: 'Loading', sitesDetails: { loops: {} } },
  facilitySnakecase: { name: 'Loading', sites_details: { loops: {} } },
  facilities: [],
  filter: { agency: null },
  facilityLookup: '',
  search: true,
  query: '',
  fetching: false,
  fetched: false,
  error: null,
  nearbyId: null,
  nearby: [],
  nearbyFetching: false,
  nearbyFetched: false
}

export default function reducer(
  state = initialValues,
  action
) {
  switch (action.type) {
    case 'RESET_FACILITY': {
      return { ...state, fetching: false, fetched: false, error: null, facility: initialValues.facility, facilitySnakecase: initialValues.facilitySnakecase }
    }
    case 'FETCH_FACILITY': {
      return { ...state, fetching: true, fetched: false, error: null }
    }
    case 'FETCH_FACILITY_REJECTED': {
      return { ...state, fetching: false, error: action.payload }
    }
    case 'FETCH_FACILITY_FULFILLED': {
      return {
        ...state,
        fetching: false,
        fetched: true,
        facility: camelcaseKeys(action.payload, { deep: true }),
        facilitySnakecase: action.payload,
        error: null
      }
    }
    case 'FETCH_AR_FULFILLED': {
      return {
        ...state,
        fetching: false,
        fetched: true,
        facility: camelcaseKeys(action.payload.facility, { deep: true }),
        facilitySnakecase: action.payload.facility,
        error: null
      }
    }
    case 'FETCH_FACILITIES': {
      return { ...state, fetching: true }
    }
    case 'FETCH_FACILITIES_REJECTED': {
      return { ...state, fetching: false, error: action.payload }
    }
    case 'FETCH_FACILITIES_FULFILLED': {
      return {
        ...state,
        fetching: false,
        fetched: true,
        facilities: action.payload,
        error: null
      }
    }
    case 'SET_FACILITY_LOOKUP': {
      return {
        ...state,
        search: false,
        facilityLookup: action.payload
      }
    }
    case 'SET_FACILITY_FILTER': {
      return {
        ...state,
        search: false,
        filter: action.payload
      }
    }
    case 'FACILITIES_QUERY': {
      return {
        ...state,
        query: action.payload
      }
    }
    case 'FETCH_NEARBY_FACILITIES': {
      return {
        ...state,
        nearbyFetching: true,
        nearbyFetched: false,
        nearbyId: action.payload
      }
    }
    case 'FETCH_NEARBY_FACILITIES_REJECTED': {
      return {
        ...state,
        nearbyFetching: false,
        nearbyFetched: false,
        error: action.payload,
        nearby: [],
        nearbyId: null
      }
    }
    case 'FETCH_NEARBY_FACILITIES_FULFILLED': {
      return {
        ...state,
        nearbyFetching: false,
        nearbyFetched: true,
        nearby: action.payload,
        error: null
      }
    }
    default: {
      return state
    }
  }
}
