import React, { useEffect, useState } from 'react'
import { Route, Switch } from 'react-router'
import { useSelector } from 'react-redux'
// import posthog from 'posthog-js'

import clsx from 'clsx'

import useApiDown from '../../hooks/useApiDown'
import requireAuth from '../sessions/authenticated'

import Nav from './Nav'
import Header from './Header'

import FlashMessage from '../flashMessage'
// import SiteSelectorModal from '../request/form/SitesSelector/Modal'

import Meta from './Meta'

const Imports = React.lazy(() => import('../Realtime/Imports'))
const RequestEdit = React.lazy(() => import('../request/edit/Index'))
const Plan = React.lazy(() => import('../Plan/Index'))
const PageAbout = React.lazy(() => import('../pages/about'))
const Requests = React.lazy(() => import('../request/Index'))
const RequestForm = React.lazy(() => import('../request/form/index'))
const RequestShow = React.lazy(() => import('../request/show/Index'))
const FrontPage = React.lazy(() => import('../FrontPage'))
const Facility = React.lazy(() => import('../facility/Show/Index'))
const Site = React.lazy(() => import('../site/Show/Index'))
const SessionNew = React.lazy(() => import('../sessions/new'))
const SessionCreate = React.lazy(() => import('../sessions/create'))
const SessionDestroy = React.lazy(() => import('../sessions/destroy'))
const UserSettings = React.lazy(() => import('../user/Index'))
const RequestStatusUpdate = React.lazy(() => import('../request/StatusUpdate'))
const FourOhFour = React.lazy(() => import('../pages/FourOhFour'))
const FacilityGroupIndex = React.lazy(() => import('../FacilityGroup/Index'))
const FacilityGroupShow = React.lazy(() => import('../FacilityGroup/Show/Index'))
const Payment = React.lazy(() => import('../Premium/Payment'))
const Feed = React.lazy(() => import('../Feed/Wrapper'))
const Faq = React.lazy(() => import('../pages/Faq'))
const SurveyWho = React.lazy(() => import('../user/Survey/Who/Index'))
const AvailabilityMatchClick = React.lazy(() => import('../availabilityMatchClick/Show'))
const RecentlyAdded = React.lazy(() => import('../pages/RecentlyAdded/Index'))

if (process.env.NODE_ENV === 'production') {
  require('../../tailwind.generated.css')
} else {
  require('../../tailwind.all.css')
}

const LayoutV2 = () => {
  useApiDown()
  const currentPath = useSelector(store => store.router.location.pathname)
  const [navOpen, setNavOpen] = useState(false)

  useEffect(() => {
    window.scrollTo(0, 0)
    // posthog.capture('$pageview')
  }, [currentPath])

  // <SiteSelectorModal />

  return (
    <div className="sans bg-white text-gray-700 min-h-screen">
      <Meta />
      <Header navClick={() => setNavOpen(!navOpen)} />
      <div className="container max-w-full">
        <div className="flex w-full">
          <Nav navOpen={navOpen} onClose={() => setNavOpen(false)} />

          <div className={clsx('flex-1', navOpen && '-ml-32')}>
            <div className="my-1 pt-2 pb-2 px-6">
              <FlashMessage />
              <React.Suspense fallback={<> </>}>
                <Switch>
                  <Route exact path="/premium" component={Payment} />
                  <Route exact path="/about" component={PageAbout} />
                  <Route exact path="/faq" component={Faq} />
                  <Route
                    exact
                    path={['/survey', '/survey/:answer([01234]{1})/:token', '/survey/:answer([01234]{1})']}
                    component={SurveyWho}
                  />

                  <Route exact path="/:from([wet]{1})/:id" component={AvailabilityMatchClick} />

                  <Route path="/plan" component={Plan} />
                  <Route path="/feed" component={Feed} />
                  <Route path="/notifications" component={Feed} />
                  <Route path="/active" component={Requests} />
                  <Route path="/expired" component={Requests} />

                  <Route path="/logs/:facilityId/sg/:siteGroupId" component={Imports} />
                  <Route path="/logs/:facilityId/:siteId" component={Imports} />
                  <Route path="/logs/:facilityId" component={Imports} />
                  <Route path="/logs" component={Imports} />

                  <Route
                    path="/edit/:uuid([a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[89aAbB][a-f0-9]{3}-[a-f0-9]{12})"
                    component={RequestEdit}
                  />

                  <Route exact path="/" component={requireAuth(Requests, FrontPage)} />

                  <Route exact path="/new" component={RequestForm} />
                  <Route exact path="/new/:slug" component={RequestForm} />

                  <Route
                    exact
                    path="/:uuid([a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[89aAbB][a-f0-9]{3}-[a-f0-9]{12})"
                    component={RequestShow}
                  />
                  <Route
                    exact
                    path="/:status([cag]{1})/:uuid([a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[89aAbB][a-f0-9]{3}-[a-f0-9]{12})"
                    component={RequestStatusUpdate}
                  />

                  <Route exact path="/explore/state/:state" component={FacilityGroupIndex} />
                  <Route exact path="/explore/:id" component={FacilityGroupShow} />
                  <Route exact path="/explore" component={FacilityGroupIndex} />

                  <Route exact path="/added" component={RecentlyAdded} />

                  <Route exact path="/f/:id/:tab([a,c])" component={Facility} />
                  <Route exact path="/f/:id" component={Facility} />
                  <Route exact path="/f/:facilityId/s/:id" component={Site} />

                  <Route exact path="/sign-in" component={SessionNew} />
                  <Route exact path="/sign-in/:token" component={SessionCreate} />
                  <Route
                    exact
                    path="/sign-in/:token/settings"
                    render={props => <SessionCreate {...props} redirect="/settings" />}
                  />

                  <Route
                    exact
                    path="/sign-in/:token/renew"
                    render={props => <SessionCreate {...props} redirect="/premium/renew" />}
                  />

                  <Route
                    exact
                    path="/sign-in/:token/sms"
                    render={props => <SessionCreate {...props} redirect="/premium/sms" />}
                  />

                  <Route
                    exact
                    path="/sign-in/:token/thanks"
                    render={props => <SessionCreate {...props} redirect="/premium/thanks" />}
                  />

                  <Route
                    exact
                    path="/sign-in/:token/upgrade"
                    render={props => <SessionCreate {...props} redirect="/premium/upgrade" />}
                  />

                  <Route exact path="/sign-out" component={SessionDestroy} />

                  <Route exact path="/premium/:product" component={Payment} />

                  <Route exact path="/settings" component={requireAuth(UserSettings, SessionNew)} />
                  <Route path="*" component={FourOhFour} />
                </Switch>
              </React.Suspense>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LayoutV2
