import React from 'react'
import { hydrate, render } from 'react-dom'

import { Provider } from 'react-redux'

import { userSettings } from './actions/userActions'

import store from './store'

import Layout from './components/layout/index'
// import posthog from 'posthog-js'

import './index.css'

// posthog.init('phc_9x4ZNNMXWUMlr3Uljj2xBW1x1FYg2AwJwrTV35atPdU', { api_host: 'https://app.posthog.com' })

const rootElement = document.getElementById('root')

const token = localStorage.getItem('token')
if (token && token !== 'null') {
  store.dispatch({ type: 'SESSION_SUCCESS', payload: { token } })
  store.dispatch(userSettings())
}

if (rootElement.hasChildNodes()) {
  hydrate(
    <Provider store={store}>
      <Layout />
    </Provider>,
    rootElement
  )
} else {
  render(
    <Provider store={store}>
      <Layout />
    </Provider>,
    rootElement
  )
}

window.snapSaveState = () => ({
  __PRELOADED_STATE__: store.getState()
});

// <Route path="/:uuid([a-f0-9]{8}-[a-f0-9]{4}-4[a-f0-9]{3}-[89aAbB][a-f0-9]{3}-[a-f0-9]{12})" component={RequestHeader}/>

// Custom route based on if first app touch
// https://reacttraining.com/react-router/web/example/modal-gallery

// import registerServiceWorker from './registerServiceWorker';
// registerServiceWorker();
