import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'

import Card from './utils/Card'

const FlashMessage = () => {
  const message = useSelector(store => store.flash.message)
  const style = useSelector(store => store.flash.style)

  useEffect(() => {
    if (!!message) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
    }
  }, [message])

  if (!!!message) {
    return null
  }

  return (
    <Card color={style === 'error' ? 'red' : 'green'} className="mb-12">
      <span className="text-2xl">{message}</span>
    </Card>
  )
}

export default FlashMessage
