import camelcaseKeys from "camelcase-keys";

export default function reducer(
  state = {
    matches: [],
    match: {
      reserve: {
        post: {}
      },
      site: {
        site_num: null
      },
      id: null
    },
    fetching: false,
    fetched: false,
    error: null,
    matchesCalendar: [],
    feed: [],
    feedFetching: false,
    feedFetched: false,
    matches_usedirect_cart: []
  },
  action
) {
  switch (action.type) {
    case "FETCH_MATCHES": {
      return { ...state, matches: [], fetching: true };
    }
    case "FETCH_MATCHES_REJECTED": {
      return { ...state, fetching: false, error: action.payload };
    }
    case "FETCH_MATCHES_FULFILLED": {
      return {
        ...state,
        fetching: false,
        fetched: true,
        matches: camelcaseKeys(action.payload, { deep: true })
      };
    }
    case "FETCH_MATCH_USEDIRECT_CART_FULFILLED": {
      return {
        ...state,
        matches_usedirect_cart: camelcaseKeys(action.payload, { deep: true })
      };
    }
    case "FETCH_MATCHES_CALENDAR_FULFILLED": {
      return {
        ...state,
        fetching: false,
        fetched: true,
        matchesCalendar: action.payload
      };
    }
    case "FETCH_MATCH": {
      return { ...state, fetching: true };
    }
    case "FETCH_MATCH_REJECTED": {
      return { ...state, fetching: false, error: action.payload };
    }
    case "FETCH_MATCH_FULFILLED": {
      return {
        ...state,
        fetching: false,
        fetched: true,
        match: action.payload
      };
    }
    case "FETCH_MATCHES_FEED": {
      return {
        ...state,
        feedFetching: true,
        feedFetched: false
      }
    }
    case "FETCH_MATCHES_FEED_FULFILLED": {
      return {
        ...state,
        feedFetching: false,
        feedFetched: true,
        feed: camelcaseKeys(action.payload, { deep: true })
      }
    }
    case "FETCH_MATCHES_FEED_REJECTED": {
      return {
        ...state,
        feedFetching: false,
        feedFetched: false
      }
    }
    default: {
      return state;
    }
  }
}
